* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Segoe UI";
 
 }
 @media only screen and (max-width: 1199px) {
   *{justify-content: center;}
 }
 .Appp {
   
   align-items: center;
   
 }
  button { border:none; } 
  html{scroll-behavior: smooth;}
  .webviewer {
    flex: 1;
    margin: 8px;
    -webkit-box-shadow: 1px 1px 10px #999;
            box-shadow: 1px 1px 10px #999;
  }
  
  html,body{max-width: 1920px;margin: auto;font-family: 'Varela Round', sans-serif;font-display: swap;}
  #root{background-image:url(https://meddialogue.eu/wp-content/uploads/2020/11/Group-18.png);background-size: contain;background-repeat: repeat;}
  body{background-image:url(https://meddialogue.eu/wp-content/uploads/2020/11/Group-18.png);background-size: contain;background-repeat: repeat;}
  @keyframes blackWhite {  
    0% { color: #166996; }
    50% { color: #20709b; }
    51% { color: #297fad; }
    100% { color: #3d95c5; }
  }